<template>
  <div id="app" :class="$route.name">
    <div class="wrapper">

      <cookie-accept-decline
        :ref="'cookiePanel'"
        :elementId="'cookie-panel'"
        :debug="false"
        :position="'top'"
        :type="'bar'"
        :disableDecline="true"
        :showPostponeButton="false"
        @status="cookieStatus"
        @clicked-accept="cookieClickedAccept"
        @clicked-decline="cookieClickedDecline"
      >
        <div slot="postponeContent">
          &times;
        </div>

        <!-- Optional -->
        <div slot="message">
          <h3>Set cookie preferences</h3>
          <div>
            <p>We use cookies to give you enhanced functionality and improve the website to give you the best experience.</p>
            <p>Please tell us if you agree to the use of non-essential cookies.</p>
          </div>
          <!-- <p>We use cookies to ensure you get the best experience on our website. <a href="https://cookiesandyou.com/" target="_blank">Learn More...</a></p> -->
        </div>

        <!-- Optional -->
        <div slot="declineContent">
          Opt out
        </div>

        <!-- Optional -->
        <div slot="acceptContent">
          I agree
        </div>
      </cookie-accept-decline>

      <!-- <button @click="removeCookie" class="fixed bottom-0 left-0 bg-black text-beige pb-4 pl-4 pt-2 pr-2 z-50">Remove browser cookie</button> -->

      <Header />

      <router-view />

      <MusicPlayer v-if="false" />

    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CookieAcceptDecline from "vue-cookie-accept-decline";
import MusicPlayer from "@/components/MusicPlayer.vue";

export default {
  name: "app",
  components: {
    Header,
    'cookie-accept-decline': CookieAcceptDecline,
    MusicPlayer,
  },
  data: function () {
    return {
      status: null
    }
  },
  created() {
    console.log('%c Modern English 🌵', 'color: white; background: black; padding: 4px 2px;');  
  },
  methods: {
    cookieStatus (status) {
      // console.log('status: ' + status)
      this.status = status
    },
    cookieClickedAccept () {
      // console.log('here in accept')
      this.status = 'accept'

      //this.$ga.enable()
    },
    cookieClickedDecline () {
      // console.log('here in decline')
      this.status = 'decline'

      //this.$ga.disable();
    },
    cookieRemovedCookie () {
      // console.log('here in cookieRemoved')
      this.status = null
      this.$refs.cookiePanel.init()

      ///this.$ga.disable();
    },

    removeCookie () {
      // console.log('Cookie removed')
      this.$refs.cookiePanel.removeCookie()

     // this.$ga.disable();
    }
  },
};
</script>
