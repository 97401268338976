<template>
  <div class="connect">
    <music-player v-if="connected" />

    <button
      v-if="!connected"
      type="button"
      ref="stream"
      @click="closePopup(), (showPopup = true)"
      class="btn btn--sm hidden md:inline-flex"
      :class="showPopup ? 'bg-beige' : 'bg-yellow hover:bg-beige'"
    >
      Stream &amp; connect
    </button>

    <div
      v-if="showPopup && !connected && !showMenu"
      class="popup popup--stream"
    >
      <button type="button" @click="closePopup()" class="mb-4 text-pink">
        <svg
          class="icon icon--close text-2xl"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <g>
            <path d="M19.428 2.457l2.121 2.121-16.97 16.97-2.122-2.12z" />
            <path d="M2.457 4.578l2.121-2.12 16.97 16.97-2.12 2.121z" />
          </g>
        </svg>
      </button>
      <p class="mb-3">Connect with Desert Sessions to:</p>
      <ul class="mb-4">
        <li>Get the latest releases</li>
        <li>Get the playlist</li>
        <li>Follow Desert Sessions</li>
      </ul>

      <form
        method="post"
        @submit.prevent="connect()"
        novalidate="true"
        class="w-64"
      >
        <h3 class="uppercase mb-4 text-xl text-pink">Connect via</h3>
        <div class="flex justify-center mb-5">
          <label class="custom-radio w-24">
            <input
              type="radio"
              name="service"
              value="Spotify"
              v-model="service"
            />
            <svg
              class="icon icon--spotify"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M9.928 14.396c-1.309.004-2.817.2-4.305.545-.43.1-.673.477-.583.89.09.41.476.656.904.562a21.58 21.58 0 0 1 3.044-.46 14.66 14.66 0 0 1 3.355.126c1.468.233 2.845.72 4.12 1.487.383.23.834.113 1.047-.254.21-.362.09-.799-.285-1.028a12.24 12.24 0 0 0-1.903-.942c-1.663-.652-3.394-.926-5.394-.926m.045-2.041c1.701.01 3.367.248 4.99.768.942.3 1.842.694 2.69 1.204.399.24.843.2 1.157-.093.467-.437.38-1.163-.19-1.504-2.257-1.352-4.726-2.007-7.33-2.201a17.497 17.497 0 0 0-4.18.185c-.65.108-1.29.253-1.918.446-.449.138-.728.573-.662 1.01.09.605.634.946 1.243.765 1.371-.405 2.774-.574 4.2-.58M9.811 6.35c-.535-.005-1.509.054-2.478.175-.933.116-1.854.29-2.756.557-.236.069-.442.181-.602.372a1.13 1.13 0 0 0-.088 1.328c.268.416.77.61 1.257.468a16.563 16.563 0 0 1 3.43-.597 22.381 22.381 0 0 1 5.181.21c1.888.298 3.703.815 5.367 1.786.255.149.532.181.817.11.44-.11.77-.467.835-.896.072-.48-.125-.91-.541-1.158-1.124-.667-2.328-1.143-3.58-1.5-2.092-.594-4.23-.849-6.842-.855M11.993 24C5.36 24-.001 18.624 0 11.977.002 5.354 5.386-.003 12.038 0 18.649.004 24.003 5.393 24 12.039 23.996 18.638 18.613 24 11.993 24"
              />
            </svg>
            <span>Spotify</span>
          </label>
          <label class="custom-radio w-24">
            <input
              type="radio"
              name="service"
              value="Apple"
              v-model="service"
            />
            <svg
              class="icon icon--apple"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M17.108 5.27c1.675 0 3.45.909 4.715 2.48-4.144 2.263-3.47 8.159.715 9.736-.576 1.274-.852 1.841-1.593 2.967-1.036 1.571-2.495 3.526-4.303 3.544-1.607.014-2.02-1.043-4.202-1.032-2.18.013-2.634 1.05-4.241 1.035-1.81-.017-3.192-1.782-4.228-3.354C1.076 16.254.773 11.1 2.56 8.36 3.827 6.41 5.829 5.27 7.712 5.27c1.917 0 3.121 1.048 4.707 1.048 1.537 0 2.473-1.049 4.689-1.049zM17.096 0c.228 1.465-.402 2.897-1.232 3.912-.89 1.087-2.421 1.928-3.901 1.886-.27-1.403.424-2.844 1.265-3.816C14.153.912 15.74.09 17.096 0z"
              />
            </svg>
            <span>Apple Music</span>
          </label>
        </div>

        <div v-if="service == 'Apple'" class="text-left mb-4">
          <label class="block mb-4">
            <span class="block mb-2">Enter email</span>
            <input
              type="email"
              v-model="email"
              class="w-full p-3 border-t-3 border-b-3 bg-blue text-black text-xs- leading-tight"
              :class="error ? 'has-error' : ''"
              placeholder="email@example.com"
              style="min-width: 0px"
            />
          </label>
          <a
            href="https://confirmsubscription.com/h/r/8D4C80650DDB3CEF2540EF23F30FEDED"
            target="_blank"
            class="block text-xs hover:text-blue transition px-4"
            >Stay updated with our newsletter</a
          >
        </div>

        <button
          v-if="service"
          type="submit"
          class="btn w-40 mt-1 mx-auto bg-pink hover:bg-orange text-black"
        >
          Continue
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import MusicPlayer from "@/components/MusicPlayer.vue";
import Header from "@/components/Header.vue";
import { EventBus } from "@/eventBus.js";
import axios from "axios";

export default {
  name: "Connect",
  props: {
    showMenu: Boolean,
  },
  components: {
    MusicPlayer,
    Header,
  },
  beforeMount() {
    window.onSpotifyWebPlaybackSDKReady = () => console.log('Spotify SDK initialised.');
  },
  data: function () {
    return {
      showPopup: false,
      service: "",
      connected: false,
      email: "",
      error: "",
    };
  },
  computed: {
    edit_connect: {
      get: function () {
        return this.connected;
      },
      set: function (val) {
        this.connected = val;
      },
    },
  },
  methods: {
    closePopup: function () {
      this.showPopup = false;
      this.showShare = false;
      this.hasCopied = false;
    },

    validEmail: function (val) {
      var regex = new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
      );
      return regex.test(val);
    },

    connect: function () {
      if (this.service === "Spotify") {
        this.error = "";
        this.spotifyAuth();
      } else if (this.service === "Apple") {
        if (this.email && this.validEmail(this.email)) {
          this.error = "";
          appleMusicKit.authorise();
        } else {
          this.error = "Enter your email!";
        }
      } else {
        this.error = "Select a service!";
      }
    },

    spotifyAuth: function () {
      var currentUrl = window.location.href;

      function login(callback, self) {
        // var self = self
        var CLIENT_ID = "15fef11570eb44c1b067784b6fd1f9bd";
        var REDIRECT_URI = currentUrl + "callback";
        // console.log(REDIRECT_URI)

        function getLoginURL(scopes) {
          return (
            "https://accounts.spotify.com/authorize?client_id=" +
            CLIENT_ID +
            "&redirect_uri=" +
            encodeURIComponent(REDIRECT_URI) +
            "&scope=" +
            encodeURIComponent(scopes.join(" ")) +
            "&response_type=token" +
            "&show_dialog=true"
          );
        }

        var url = getLoginURL([
          "user-read-private",
          "user-read-email",
          "streaming",
          "playlist-modify-public",
          "user-modify-playback-state",
          "user-follow-read",
          "user-follow-modify",
        ]);

        var width = 320;
        var height = 630;
        var left = screen.width / 2 - width / 2;
        var top = screen.height / 2 - height / 2;

        function messageFunc() {
          var hash = JSON.parse(event.data);
          console.log("Hash: " + hash);
          if (hash.type === "access_token") {
            callback(hash.access_token);
          }
        }

        window.addEventListener("message", messageFunc);

        function newWindow(url, title, width, height, onClose, onDidNotOpen) {
          let left = window.offsetWidth / 2 - width / 2;
          let top = window.offsetHeight / 2 - height / 2;
          let newWindow = window.open(
            url,
            title,
            "status=no,height=" +
              height +
              ",width=" +
              width +
              ",resizable=1,scrollbars=1,left=" +
              left +
              ",top=" +
              top +
              ",screenX=" +
              left +
              ",screenY=" +
              top +
              ",toolbar=no,menubar=no,location=no,directories=no,fullscreen=no"
          );

          // Didn't open? Blocked?
          if (!newWindow || !newWindow.focus) {
            // Exit and possibly run error callback
            return onDidNotOpen && onDidNotOpen.call ? onDidNotOpen() : false;
          }

          // It opened. Whoo.
          newWindow.focus();

          // Listen for onClose callback
          if (onClose && onClose.call) {
            let closed = setInterval(function () {
              if (newWindow.closed) {
                clearInterval(closed);
                onClose();
              }
            }, 250);
          }
        }

        function onClose() {
          if (
            self.$cookies.isKey("spotifyAuthToken") &&
            self.$cookies.isKey("userType")
          ) {
            //self.spotifyFollow()
            self.edit_connect = true;
          }

          EventBus.$emit("initMusicPlayer");
          window.removeEventListener("message", messageFunc);
        }

        function onDidNotOpen() {
          console.log("Window did not open");
        }

        var w = newWindow(url, "Spotify Auth", 360, 600, onClose, onDidNotOpen);
      }
      login(null, this);
    },

    spotifyFollow: async function () {
      var the_token = this.$cookies.get("spotifyAuthToken");
      // var artist_id = '6vXTefBL93Dj5IqAWq6OTv'
      var following = false;

      axios({
        url: "https://api.spotify.com/v1/me/following/contains?type=artist&ids=6vXTefBL93Dj5IqAWq6OTv",
        method: "get",
        headers: {
          Authorization: "Bearer " + the_token,
        },
      })
        .then(function (response) {
          // console.log(response)
          following = response.data[0];
        })
        .then(function () {
          if (following === false) {
            axios({
              url: "https://api.spotify.com/v1/me/following?type=artist&ids=6vXTefBL93Dj5IqAWq6OTv",
              method: "put",
              timeout: 8000,
              headers: {
                Authorization: "Bearer " + the_token,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then(function (response) {
                // console.log(response)
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    checkSpotifyPremium: function () {
      if (this.$cookies.isKey("spotifyAccountPremium")) {
        return true;
      } else {
        return false;
      }
    },

    appleAuthed: function () {
      var date = new Date();
      date = new Date(date.getTime() + 60 * 60000);
      this.$cookies.set("userType", "Apple", date);
      this.edit_connect = true;
      EventBus.$emit("initMusicPlayer");
    },
  },

  watch: {
    service: function () {
      if (this.error) {
        this.error = "";
      }
    },
    email: function () {
      this.error = "";
    },
  },

  created: function () {
    this.connected = false;
    //Create an event listener for the apple music button
    var self = this;
    document.addEventListener(
      "authed",
      function (e) {
        self.appleAuthed();
        EventBus.$emit("initMusicPlayer");
      },
      false
    );

    // console.log(this.$route.path);
  },
};
</script>
