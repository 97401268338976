<template>
  <div class="fixed inset-0 flex flex-col border-8 border-white bg-black text-beige z-50" style="background-color: rgba(0, 0, 0, .75);">

    <div class="flex-auto flex flex-col justify-center items-center px-6 py-6">

      <div class="pb-12">

        <div v-if="type == 'image'">

          <h2 class="text-3xl uppercase text-center">
            You’ve unlocked
            <span class="block" :class="'text-' + color">{{ character }}</span>
          </h2>

          <div class="mt-6 text-center max-w-xs">
            <img :src="symbols">
          </div>

          <div v-if="audio_1" class="flex items-center px-4 py-3 mt-8 border-3 border-black bg-pink text-black">
            <button v-if="playing == 'no'" type="button" class="btn btn--audio h-12 w-12 mr-3 border-3 rounded-full bg-blue hover:bg-yellow" @click="play()">
              <svg class="icon icon--play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M3 0v24l18.947-12z"/>
              </svg>
            </button>
            <button v-else-if="playing == 'yes'" type="button" class="btn btn--audio h-12 w-12 mr-3 border-3 rounded-full bg-blue hover:bg-yellow" @click="pause()">
              <svg class="icon icon--pause" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                  <path d="M2 0h6.316v24H2zM15.895 0h6.316v24h-6.316z"/>
                </g>
              </svg>
            </button>
            <button v-else type="button" class="btn btn--audio h-12 w-12 mr-3 border-3 rounded-full bg-blue hover:bg-yellow" @click="play()">
              <svg class="icon icon--reset" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M2.115 0l3.188.335-.433 4.11c1.877-1.9 4.281-2.88 7.147-2.88 6.195 0 11.217 5.023 11.217 11.218S18.212 24 12.017 24 .8 18.978.8 12.783h3.205a8.012 8.012 0 108.012-8.012c-2.015 0-3.64.646-4.94 1.998l3.756.396-.335 3.187-9.367-.984L2.115 0z"/>
              </svg>
            </button>
            {{ character }}’s voice memo
            <audio :src="audio_1" controls- ref="audioPlayer" @ended="reset()"></audio>
          </div>

        </div>

        <div v-else>

          <h2 class="text-3xl uppercase text-center">
            You’ve unlocked
            <span class="block" :class="'text-' + color">{{ character }}</span>
          </h2>

          <div v-if="audio" class="flex items-center px-4 py-3 mt-8 border-3 border-black bg-pink text-black">
            <button v-if="playing == 'no'" type="button" class="btn btn--audio h-12 w-12 mr-3 border-3 rounded-full bg-blue hover:bg-yellow" @click="play()">
              <svg class="icon icon--play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M3 0v24l18.947-12z"/>
              </svg>
            </button>
            <button v-else-if="playing == 'yes'" type="button" class="btn btn--audio h-12 w-12 mr-3 border-3 rounded-full bg-blue hover:bg-yellow" @click="pause()">
              <svg class="icon icon--pause" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                  <path d="M2 0h6.316v24H2zM15.895 0h6.316v24h-6.316z"/>
                </g>
              </svg>
            </button>
            <button v-else type="button" class="btn btn--audio h-12 w-12 mr-3 border-3 rounded-full bg-blue hover:bg-yellow" @click="play()">
              <svg class="icon icon--reset" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M2.115 0l3.188.335-.433 4.11c1.877-1.9 4.281-2.88 7.147-2.88 6.195 0 11.217 5.023 11.217 11.218S18.212 24 12.017 24 .8 18.978.8 12.783h3.205a8.012 8.012 0 108.012-8.012c-2.015 0-3.64.646-4.94 1.998l3.756.396-.335 3.187-9.367-.984L2.115 0z"/>
              </svg>
            </button>
            {{ character }}’s voice memo
            <audio :src="audio" controls- ref="audioPlayer" @ended="reset()"></audio>
          </div>

          <div v-else>
            <!-- Surprise! -->
          </div>

          <div v-if="character == 'Töôrnst Hülpft'">
            <a href="https://desertsessions.ffm.to/StonerBundle" target="_blank" class="btn block w-48 mt-6 mx-auto bg-yellow hover:bg-orange text-black">Stoner Bundle</a>
          </div>

          <div v-if="fromShuffle">
            <a href="https://confirmsubscription.com/h/r/1FCC4B3B128079962540EF23F30FEDED" target="_blank" class="btn block w-48 mt-6 mx-auto bg-blue hover:bg-orange text-black">Congratulations!</a>
          </div>

        </div>

        <button type="button" @click="closeOverlay()" class="btn block w-40 mt-8 mx-auto bg-yellow hover:bg-orange text-black">Continue</button>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "Overlay",
  props: {
    character: String,
    color: String,
    type: String,
    symbols: String,
    audio_1: String,
    audio: String,
    fromShuffle: Boolean,
  },
  data: function () {
    return {
      playing: 'no',
    }
  },
  methods: {
    play: function () {
      // alert('Play!');
      this.playing = 'yes';
      this.$refs.audioPlayer.play();
    },
    pause: function () {
      // alert('Pause!');
      this.playing = 'no';
      this.$refs.audioPlayer.pause();
    },
    reset: function () {
      // alert('Reset!');
      this.playing = 'end';
    },

    // selectCode: function (event) {
    //   // alert('Select!');
    //   this.$refs.codeInput.select();
    // },
    // copyCode: function (event) {
    //   // alert('Copy!');
    //   this.selectCode();
    //   document.execCommand('copy');
    // },

    closeOverlay: function () {
      // alert('Close overlay!');

      // if ( this.type == 'symbol' ) {
      //   this.playing = 'no';
      //   this.$refs.audioPlayer.load();
      // }

      this.$emit('close');
    }
  }
};
</script>
