<template>
    <main class="wrapper__inner">

        <div class="content content--home">

            <div class="background">
                <div class="background__top-left"></div>
                <div class="background__top-right"></div>
                <div class="background__bottom-left"></div>
                <div class="background__bottom-right"></div>
            </div>

            <div class="view">

                <div class="view__finder"></div>

                <div class="slider" :class="'slider--' + top.color">

                    <slider ref="top" :options="options" :autoplay-speed="2750" @after-change="current('top', $event)">

                        <div v-for="(tile, index) in tiles.top" class="tile" :key="(index+1)">
                            <div class="tile__outer" :ref="'top-' + (index+1)" :data-character_id="tile.character_id" :data-type="tile.type">
                                <div class="tile__inner" :style="'background-image: url(' + tile.path + ')'">
                                </div>
                            </div>
                        </div>

                    </slider>

                    <button type="button" @click="prev('top', $event)" class="slider__button slider__button--prev">
                        <svg class="icon icon--arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M14.121 12l6.94 6.94-2.122 2.12L9.88 12l9.06-9.06 2.122 2.12z"/>
                                <path d="M6.121 12l6.94 6.94-2.122 2.12L1.88 12l9.06-9.06 2.122 2.12z"/>
                            </g>
                        </svg>
                    </button>
                    <button type="button" @click="next('top', $event)" class="slider__button slider__button--next">
                        <svg class="icon icon--arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M9.879 12l-6.94 6.94 2.122 2.12L14.12 12l-9.06-9.06-2.122 2.12z"/>
                                <path d="M17.879 12l-6.94 6.94 2.122 2.12L22.12 12l-9.06-9.06-2.122 2.12z"/>
                            </g>
                        </svg>
                    </button>

                </div>

                <div class="slider" :class="'slider--' + middle.color">

                    <slider ref="middle" :options="options" :autoplay-speed="3350" @after-change="current('middle', $event)">

                        <div v-for="(tile, index) in tiles.middle" class="tile" :key="(index+1)">
                            <div class="tile__outer" :ref="'middle-' + (index+1)" :data-character_id="tile.character_id" :data-type="tile.type">
                                <div class="tile__inner" :style="'background-image: url(' + tile.path + ')'">
                                </div>
                            </div>
                        </div>

                    </slider>

                    <button type="button" @click="prev('middle', $event)" class="slider__button slider__button--prev">
                        <svg class="icon icon--arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M14.121 12l6.94 6.94-2.122 2.12L9.88 12l9.06-9.06 2.122 2.12z"/>
                                <path d="M6.121 12l6.94 6.94-2.122 2.12L1.88 12l9.06-9.06 2.122 2.12z"/>
                            </g>
                        </svg>
                    </button>
                    <button type="button" @click="next('middle', $event)" class="slider__button slider__button--next">
                        <svg class="icon icon--arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M9.879 12l-6.94 6.94 2.122 2.12L14.12 12l-9.06-9.06-2.122 2.12z"/>
                                <path d="M17.879 12l-6.94 6.94 2.122 2.12L22.12 12l-9.06-9.06-2.122 2.12z"/>
                            </g>
                        </svg>
                    </button>

                </div>

                <div class="slider" :class="'slider--' + bottom.color">

                    <slider ref="bottom" :options="options" :autoplay-speed="2450" @after-change="current('bottom', $event)">

                        <div v-for="(tile, index) in tiles.bottom" class="tile" :key="(index+1)">
                            <div class="tile__outer" :ref="'bottom-' + (index+1)" :data-character_id="tile.character_id" :data-type="tile.type">
                                <div class="tile__inner" :style="'background-image: url(' + tile.path + ')'">
                                </div>
                            </div>
                        </div>

                    </slider>

                    <button type="button" @click="prev('bottom', $event)" class="slider__button slider__button--prev">
                        <svg class="icon icon--arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M14.121 12l6.94 6.94-2.122 2.12L9.88 12l9.06-9.06 2.122 2.12z"/>
                                <path d="M6.121 12l6.94 6.94-2.122 2.12L1.88 12l9.06-9.06 2.122 2.12z"/>
                            </g>
                        </svg>
                    </button>
                    <button type="button" @click="next('bottom', $event)" class="slider__button slider__button--next">
                        <svg class="icon icon--arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M9.879 12l-6.94 6.94 2.122 2.12L14.12 12l-9.06-9.06-2.122 2.12z"/>
                                <path d="M17.879 12l-6.94 6.94 2.122 2.12L22.12 12l-9.06-9.06-2.122 2.12z"/>
                            </g>
                        </svg>
                    </button>

                </div>

            </div>

            <div class="shuffle">
                <button type="button" class="btn btn--sm bg-pink hover:bg-red" @click="shuffle()">
                    <svg class="icon icon--shuffle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M21.77 1.9L24 14.466l-6.511 1.139-1.223 6.537L0 19.442 2.352 6.878l4.399.73-.53-2.986L21.77 1.9zM4.344 9.727l-1.438 7.68 11.366 1.887.604-3.231-6.425 1.126-1.24-6.987-2.867-.475zm15.412-4.954L9.101 6.64l1.363 7.677 10.656-1.867-1.363-7.676z"/>
                    </svg>
                    Shuffle
                </button>
                <div class="share share--shuffle">
                    <button type="button" class="btn btn--sm ml-3 whitespace-nowrap" :class="showShareMix ? 'bg-beige' : 'bg-orange hover:bg-beige'" @click="showShareMix = true">
                        Share this mix
                    </button>

                    <div v-if="showShareMix" class="popup popup--sharemix">

                        <button type="button" @click="showShareMix = false" class="mb-3 text-pink">
                            <svg class="icon icon--close text-2xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g>
                                    <path d="M19.428 2.457l2.121 2.121-16.97 16.97-2.122-2.12z"/>
                                    <path d="M2.457 4.578l2.121-2.12 16.97 16.97-2.12 2.121z"/>
                                </g>
                            </svg>
                        </button>
                        <p class="mb-3 text-xl text-pink uppercase">Share via</p>
                        <div class="flex items-center">
                            <a @click.prevent="shareSelection($event, 600, 400);" href="//www.facebook.com/sharer/sharer.php?u=" target="_blank" class="share__link">
                                <svg class="icon icon--facebook text-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M22.676 0H1.325C.593 0 0 .593 0 1.324v21.351C0 23.407.593 24 1.325 24h11.494v-9.294H9.692v-3.622h3.127V8.412c0-3.1 1.893-4.788 4.659-4.788 1.324 0 2.463.099 2.794.143v3.24h-1.917c-1.504 0-1.795.715-1.795 1.764v2.313h3.586l-.466 3.622h-3.12V24h6.116c.731 0 1.324-.593 1.324-1.325V1.325C24 .592 23.407 0 22.676 0"/>
                                </svg>
                            </a>
                            <a @click.prevent="shareSelection($event, 600, 400);" href="//twitter.com/intent/tweet?text=Desert Sessions Vol. 11 %26 12 – Feral music for the joyful destruction of normalcy – Available Oct. 25.&url=" target="_blank" class="share__link">
                                <svg class="icon icon--twitter text-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M7.548 21.755c9.057 0 14.01-7.504 14.01-14.01 0-.213-.005-.425-.014-.637A10.01 10.01 0 0 0 24 4.558a9.82 9.82 0 0 1-2.828.776 4.937 4.937 0 0 0 2.165-2.724 9.862 9.862 0 0 1-3.126 1.195 4.925 4.925 0 0 0-8.39 4.49A13.978 13.978 0 0 1 1.67 3.153a4.91 4.91 0 0 0-.667 2.474 4.92 4.92 0 0 0 2.191 4.1 4.887 4.887 0 0 1-2.23-.617c-.002.02-.002.042-.002.063A4.927 4.927 0 0 0 4.913 14a4.958 4.958 0 0 1-2.223.084 4.93 4.93 0 0 0 4.6 3.42 9.882 9.882 0 0 1-6.115 2.107A10.1 10.1 0 0 1 0 19.543a13.943 13.943 0 0 0 7.548 2.212"/>
                                </svg>
                            </a>
                            <a href="#" @click.prevent="copySelectionLink('https://desertsessions.com/')" class="p-1 mx-1 text-xl text-black hover:text-blue transition">
                                <svg class="icon icon--link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M5.849 9.199l-2.837 2.92a6.758 6.758 0 0 0-.004 9.417l.095.096.04.04a6.467 6.467 0 0 0 9.144-.132l1.07-1.102-2.182-2.12-1.07 1.101a3.424 3.424 0 0 1-4.843.068l-.033-.033-.035-.035a3.716 3.716 0 0 1 0-5.18l2.837-2.92a3.424 3.424 0 0 1 4.536-.332l2.195-2.133a6.467 6.467 0 0 0-8.913.345zm5.887-6.739l-1.07 1.102 2.183 2.12 1.07-1.101a3.425 3.425 0 0 1 4.844-.066l.067.066a3.716 3.716 0 0 1 0 5.18l-2.838 2.92a3.424 3.424 0 0 1-4.536.333l-2.195 2.132a6.467 6.467 0 0 0 8.914-.345l2.837-2.92a6.759 6.759 0 0 0-.047-9.468l-.054-.054-.03-.03a6.467 6.467 0 0 0-9.145.131z"/>
                                </svg>
                            </a>
                        </div>
                        <div v-if="hasCopied" class="mt-2">
                            <p>Copied!</p>
                        </div>
                        <div class="flex justify-center mt-3">
                            <a :href="'./share.php?top=' + top.character_id + '' + ((top.type == 'image') ? '-1' : '-1-symbol') + '&middle=' + middle.character_id + ((middle.type == 'image') ? '-2' : '-2-symbol') + '&bottom=' + bottom.character_id + ((bottom.type == 'image') ? '-3' : '-3-symbol')" class="inline-flex items-center hover:text-blue transition" target="_blank">
                                <span class="mr-2">Download</span>
                                <svg class="icon icon--instagram text-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M10.84 0h2.32c2.224.004 2.679.021 3.788.072 1.277.058 2.15.261 2.912.558.79.307 1.459.717 2.126 1.384A5.875 5.875 0 0 1 23.37 4.14c.296.762.5 1.635.557 2.912.051 1.11.069 1.564.072 3.787v2.321c-.003 2.223-.02 2.678-.072 3.787-.057 1.277-.26 2.15-.557 2.913a5.869 5.869 0 0 1-1.384 2.125 5.869 5.869 0 0 1-2.126 1.385c-.763.296-1.635.499-2.912.557-1.152.053-1.598.07-4.052.072h-1.792c-2.454-.003-2.9-.02-4.051-.072-1.277-.058-2.15-.26-2.913-.557a5.875 5.875 0 0 1-2.126-1.385A5.875 5.875 0 0 1 .63 19.86c-.296-.763-.499-1.636-.557-2.913C.022 15.838.004 15.383 0 13.16v-2.32C.004 8.615.02 8.16.073 7.051.13 5.775.333 4.902.63 4.14a5.88 5.88 0 0 1 1.384-2.126A5.88 5.88 0 0 1 4.14.63C4.903.333 5.776.13 7.053.072 8.162.022 8.616.004 10.84 0h2.32zm2.3 2.163h-2.28c-2.18.003-2.612.019-3.71.07-1.17.052-1.804.248-2.227.412-.56.218-.96.478-1.38.898-.42.42-.68.82-.897 1.38-.165.422-.36 1.058-.413 2.228-.052 1.138-.068 1.56-.07 3.969v1.76c.002 2.409.018 2.83.07 3.969.053 1.17.248 1.805.413 2.228.217.56.477.96.898 1.38.419.42.819.68 1.379.897.423.165 1.058.36 2.228.413 1.139.052 1.56.068 3.969.07h1.76c2.41-.002 2.83-.018 3.97-.07 1.17-.053 1.804-.248 2.227-.413.56-.218.96-.477 1.38-.898.42-.419.68-.82.898-1.379.164-.423.36-1.058.413-2.228.05-1.097.066-1.528.07-3.71V10.86c-.004-2.181-.02-2.613-.07-3.71-.053-1.17-.249-1.805-.413-2.227a3.718 3.718 0 0 0-.898-1.38 3.71 3.71 0 0 0-1.38-.898c-.423-.164-1.058-.36-2.228-.413-1.096-.05-1.528-.066-3.709-.07zM12 5.838a6.162 6.162 0 1 1 0 12.324 6.162 6.162 0 0 1 0-12.324zM12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm6.405-3.846a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88z"/>
                                </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="bricks"></div>

        <div class="buttons buttons--bottom">
            <Connect />
            <a href="https://desertsessions.ffm.to/vols11and12" target="_blank" class="w-1/2 btn btn--sm bg-blue hover:bg-beige">Pre-order/save</a>
        </div>

        <transition name="fade">
            <overlay v-if="toggleOverlay" @close="hideOverlay()" :character="character" :color="color" :type="type" :symbols="symbols" :audio="audio" :audio_1="audio_1" :from-shuffle="fromShuffle" />
        </transition>

        <div v-if="shuffleClicks >= 50" class="fixed inset-0 flex flex-col border-8 border-white bg-black text-beige z-50 pointer-events-none">

            <div class="flex-auto flex flex-col justify-center items-center text-center px-6 py-6">
                <h2 class="text-3xl sm:text-6xl uppercase mb-3 text-pink blink">
                    Game over
                </h2>
                <p class="text-lg text-yellow">Come back to click another day</p>
            </div>

        </div>

    </main>
</template>

<script>
    import { VueAgile } from 'vue-agile';
    import Characters from '@/data/characters.json';
    import Top from '@/data/top.json';
    import Middle from '@/data/middle.json';
    import Bottom from '@/data/bottom.json';
    import Overlay from "@/components/Overlay.vue";
    import Connect from "@/components/Connect.vue";

    export default {
        name: "home",
        components: {
            slider: VueAgile,
            overlay: Overlay,
            Connect
        },

        data: function () {
            return {
                showOverlay: false,
                showPopup: false,
                showShareMix: false,
                hasCopied: false,
                fromShuffle: false,

                options: {
                    autoplay: true,
                    slidesToShow: 1,
                    centerMode: true,
                    dots: false,
                    navButtons: false,
                },

                characters: Characters,

                tiles: {
                    top: Top,
                    middle: Middle,
                    bottom: Bottom,
                },

                top: {
                    character: '',
                    character_id: '',
                    color: '',
                    type: '',
                    symbols: '',
                    audio_1: false,
                    audio: '',
                },

                middle: {
                    character: '',
                    character_id: '',
                    color: '',
                    type: '',
                },

                bottom: {
                    character: '',
                    character_id: '',
                    color: '',
                    type: '',
                },

                shuffleClicks: 0,
            }
        },

        computed: {
            match: function () {
                if ( this.character_id && this.type ) {
                    return true;
                } else {
                    return false;
                }

            },
            character: function () {

                if ( this.top.character == this.middle.character && this.top.character == this.bottom.character ) {
                    return this.top.character;
                }

            },
            character_id: function () {

                if ( this.top.character_id == this.middle.character_id && this.top.character_id == this.bottom.character_id ) {
                    return this.top.character_id;
                }

            },
            color: function () {

                if ( this.top.character == this.middle.character && this.top.character == this.bottom.character ) {
                    return this.top.color;
                }

            },
            type: function () {

                if ( this.top.type == this.middle.type && this.top.type == this.bottom.type ) {
                    // console.log(this.top.type);
                    return this.top.type;
                }

            },
            symbols: function () {

                if ( this.top.character == this.middle.character && this.top.character == this.bottom.character ) {
                    return this.top.symbols;
                }

            },
            audio: function () {

                if ( this.top.character == this.middle.character && this.top.character == this.bottom.character ) {
                    return this.top.audio;
                }

            },
            audio_1: function () {

                if ( this.top.character == this.middle.character && this.top.character == this.bottom.character && this.top.audio_1 !== undefined ) {
                    return this.top.audio_1;
                }

            },
            toggleOverlay: function () {

                if ( this.match && this.showOverlay ) {
                    return true;
                } else {
                    return false;
                }

            }
        },

        methods: {
            action: function (event) {
                // alert('Action!');
                // console.log(event);
                event.disabled = true;

                setTimeout(function() {
                    event.disabled = false;
                }, 300);

                this.options.autoplay = false;

                this.$refs.top.disableAutoPlay();
                this.$refs.middle.disableAutoPlay();
                this.$refs.bottom.disableAutoPlay();

                this.fromShuffle = false;

                this.showOverlay = true;

            },

            prev: function (ref, event) {
                // console.log(event);

                event.target.disabled = true;

                setTimeout(function() {
                    event.target.disabled = false;
                }, 300);

                this.options.autoplay = false;

                this.$refs.top.disableAutoPlay();
                this.$refs.middle.disableAutoPlay();
                this.$refs.bottom.disableAutoPlay();

                this.fromShuffle = false;
                this.showOverlay = true;

                this.$refs[ref].goToPrev();

            },

            next: function (ref, event) {
                // console.log(event);


                event.target.disabled = true;

                setTimeout(function() {
                    event.target.disabled = false;
                }, 300);

                this.options.autoplay = false;

                this.$refs.top.disableAutoPlay();
                this.$refs.middle.disableAutoPlay();
                this.$refs.bottom.disableAutoPlay();

                this.fromShuffle = false;
                this.showOverlay = true;

                this.$refs[ref].goToNext();

            },

            shuffle: function () {

                this.shuffleClicks++;

                var random1a = Math.floor(Math.random() * this.tiles.top.length);
                // var random1b = Math.floor(Math.random() * this.tiles.top.length);
                // var random1c = Math.floor(Math.random() * this.tiles.top.length);
                var random2a = Math.floor(Math.random() * this.tiles.middle.length);
                // var random2b = Math.floor(Math.random() * this.tiles.middle.length);
                // var random2c = Math.floor(Math.random() * this.tiles.middle.length);
                var random3a = Math.floor(Math.random() * this.tiles.bottom.length);
                // var random3b = Math.floor(Math.random() * this.tiles.bottom.length);
                // var random3c = Math.floor(Math.random() * this.tiles.bottom.length);

                if ( this.options.autoplay ) {

                    this.options.autoplay = false;

                    this.$refs.top.disableAutoPlay();
                    this.$refs.middle.disableAutoPlay();
                    this.$refs.bottom.disableAutoPlay();

                }

                this.$refs.top.goTo(random1a);
                this.$refs.middle.goTo(random2a);
                this.$refs.bottom.goTo(random3a);

                // var $this = this;
                //
                // setTimeout(function() {
                //   $this.$refs.top.goTo(random1b);
                //   $this.$refs.middle.goTo(random2b);
                //   $this.$refs.bottom.goTo(random3b);
                // }, 300);
                //
                // setTimeout(function() {
                //   $this.$refs.top.goTo(random1c);
                //   $this.$refs.middle.goTo(random2c);
                //   $this.$refs.bottom.goTo(random3c);
                // }, 600);

                this.fromShuffle = true;

                this.showOverlay = true;

            },

            current: function (item, event) {

                var slide = event.currentSlide + 1;

                var character_id = this.$refs[item + '-' + slide][0].getAttribute('data-character_id');
                this[item].character_id = character_id;

                var type = this.$refs[item + '-' + slide][0].getAttribute('data-type');
                this[item].type = type;

                var $this = this;

                var character = this.characters.filter(function(i) {
                    // console.log($this[item].character_id);
                    return i.id == $this[item].character_id;
                });

                this[item].character = character[0].name;
                this[item].color = character[0].color;
                this[item].symbols = character[0].symbols;
                this[item].audio_1 = character[0].audio_1;
                this[item].audio = character[0].audio;

            },

            hideOverlay: function () {
                // alert('Close overlay!');
                this.showOverlay = false;
            },

            shareLink: function (event, w, h) {

                var url = event.target.href;
                // console.log(url);
                var title = '';
                var win = window;
                // console.log(win.top.screenY);
                var y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
                // console.log(y);
                var x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
                // console.log(x);
                return win.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);

            },

            shareSelection: function (event, w, h) {

                var topSlide = parseInt(this.$refs.top.currentSlide);
                var topEncoded = window.btoa( topSlide );
                var middleSlide = parseInt(this.$refs.middle.currentSlide);
                var middleEncoded = window.btoa( middleSlide );
                var bottomSlide = parseInt(this.$refs.bottom.currentSlide);
                var bottomEncoded = window.btoa( bottomSlide );

                var selection = 'https://desertsessions.com/%23' + topEncoded + '/' + middleEncoded + '/' + bottomEncoded;
                var url = event.target.href + selection;
                console.log(url);
                var title = '';
                var win = window;
                // console.log(win.top.screenY);
                var y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
                // console.log(y);
                var x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
                // console.log(x);
                return win.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);

            },

            copySelectionLink: function (msg) {
                // alert('Share link!');

                var el = document.createElement('textarea');

                var topSlide = parseInt(this.$refs.top.currentSlide);
                var topEncoded = window.btoa( topSlide );
                var middleSlide = parseInt(this.$refs.middle.currentSlide);
                var middleEncoded = window.btoa( middleSlide );
                var bottomSlide = parseInt(this.$refs.bottom.currentSlide);
                var bottomEncoded = window.btoa( bottomSlide );

                var url = 'https://desertsessions.com/#' + topEncoded + '/' + middleEncoded + '/' + bottomEncoded;

                el.value = url;

                document.body.appendChild(el);
                // el.focus();
                el.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    this.hasCopied = true;
                    console.log('Copying text command was ' + msg);
                } catch (err) {
                    this.hasCopied = false;
                    console.log('Oops, unable to copy');
                }

                document.body.removeChild(el);

            },
        },

        mounted: function () {
            // this.shuffle();
            // this.showOverlay = false;
            // this.options.autoplay = true;
            // this.$refs.top.toggleAutoPlay();
            // this.$refs.middle.toggleAutoPlay();
            // this.$refs.bottom.toggleAutoPlay();

            if ( this.$route.hash ) {

                var hash = this.$route.hash;
                hash = hash.substr(1);
                hash = hash.split('/');
                // console.log(hash);

                var topHash = hash[0];
                var topDecoded = window.atob( topHash );
                var middleHash = hash[1];
                var middleDecoded = window.atob( middleHash );
                var bottomHash = hash[2];
                var bottomDecoded = window.atob( bottomHash );

                this.$refs.top.disableAutoPlay();
                this.$refs.middle.disableAutoPlay();
                this.$refs.bottom.disableAutoPlay();

                this.fromShuffle = false;
                this.showOverlay = false;

                this.$refs.top.goTo( parseInt( topDecoded ) );
                this.$refs.middle.goTo( parseInt( middleDecoded ) );
                this.$refs.bottom.goTo( parseInt( bottomDecoded ) );

                window.history.replaceState({}, document.title, '/' + '');

                var $this = this;

                setTimeout(function() {
                    $this.shuffle();
                    this.shuffleClicks = 0;
                    $this.$refs.top.toggleAutoPlay();
                    $this.$refs.middle.toggleAutoPlay();
                    $this.$refs.bottom.toggleAutoPlay();
                    $this.options.autoplay = true;
                }, 4000);

            } else {

                this.shuffle();
                this.shuffleClicks = 0;
                this.fromShuffle = false;
                this.showOverlay = false;
                this.options.autoplay = true;
                this.$refs.top.toggleAutoPlay();
                this.$refs.middle.toggleAutoPlay();
                this.$refs.bottom.toggleAutoPlay();

            }

            // console.log( this.$refs.top.currentSlide + 1 );
            // console.log( this.$refs.middle.currentSlide + 1 );
            // console.log( this.$refs.bottom.currentSlide + 1 );

        }
    };
</script>
