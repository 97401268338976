<template>
	<div>
		<div class="auth_container">
			<div v-if="permission === true">
				<h3>Thank You</h3>
				<p>You can now close this window!</p>
				<button class="button-close-self" onclick="self.close()">
					Continue
				</button>
			</div>
			<div v-else>
				<h3>Oh no!</h3>
				<p>There was an error granting permissions from Spotify.</p>
				<p>You can still use this website, without some of the more advanced features</p>
				<button class="button-close-self" onclick="self.close()">
					Continue
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'auth_redirect',
		data: function () {
			return {
				permission: null,
				token: null,
				token_type: null,
				expires_in: null,
				user_type: null
			}
		},
		components: {
		},
		methods: {
			checkCookieExists: function () {
				this.getSpotifyAccountType()

				if (this.$cookies.isKey('spotifyAuthToken')) {
					this.permission = true
					return true
				}

				return false
			},

			getSpotifyAccountType: async function () {
				var token = this.token

				try {
					let res = await axios({
						url: 'https://api.spotify.com/v1/me',
						method: 'get',
						timeout: 8000,
						headers: {
							'Authorization': 'Bearer ' + token
						}
					})
					return res.data
				} catch (err) {
					console.error(err)
				}
			},

			readURLData: function () {
				var self = this
				// The spotify session only lasts for an hour
				var date = new Date()
				date = new Date(date.getTime() + 60 * 60000)

				this.token = window.location.hash.substr(1).split('&')[0].split('=')[1]
				this.token_type = window.location.hash.substr(1).split('&')[1].split('=')[1]
				this.expires_in = window.location.hash.substr(1).split('&')[2].split('=')[1]

				// Auth has passed -> so store our details
				if (this.token && this.token_type && this.expires_in) {
					// The user is obviously a spotify user
					this.$cookies.set('userType', 'Spotify', date)

					// Create a new cookie to store our auth in
					this.$cookies.set('spotifyAuthToken', this.token, date)

					// Check what type of user account the user has, premium or not
					this.getSpotifyAccountType().then(function (res) {
						// STORE USERS ID AS WE NEED THIS FOR REQUESTS
						self.$cookies.set('spotifyAccountID', res.id, date)

						// STORE USERS PREMIUM TYPE AS WELL.
						self.$cookies.set('spotifyAccountPremium', res.product, date)
					})

					this.permission = true
				} else { // The user did not grant us permision
					// The user is now using the escape hatch
					this.$cookies.set('userType', 'Hatch', date)

					// Create a new cookie to store our auth in
					if (this.$cookies.isKey('spotifyAuthToken')) {
						this.$cookies.remove('spotifyAuthToken')
					}
					// Set premium to null
					if (this.$cookies.isKey('spotifyAccountPremium')) {
						this.$cookies.remove('spotifyAccountPremium')
					}

					this.permission = false
				}
			},

			authentication_init: function () {
				// First, read the URL data to see what spotify sends back to us
				this.readURLData()
			}
		},
		mounted: function () {
			// INIT function, runs a checklist of if's to read/write our users details
			this.authentication_init()

			// setTimeout(function () {
				window.close()
			// }, 100)
		}
	}
</script>
