import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Callback from './views/Callback.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/credits",
      name: "credits",
      component: () =>
        import("./views/Credits.vue")
    },
    {
      path: "/media",
      name: "media",
      component: () =>
        import("./views/Media.vue")
    },
    {
      path: "/tour",
      name: "tour",
      component: () =>
        import("./views/Tour.vue")
    },
    {
      path: "/news",
      name: "news",
      component: () =>
        import("./views/News.vue")
    },
    // {
    //   path: "/post",
    //   name: "post",
    //   component: () =>
    //     import("./views/Post.vue")
    // },
    {
      path: "/article/:slug",
      name: "post",
      props: true,
      component: () =>
        import("./views/Post.vue")
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () =>
        import("./views/PrivacyPolicy.vue")
    },
    {
      path: '/callback',
      name: 'callback',
      component: Callback
    },

    //4 0 4
    {
      path: "/*",
      name: "404",
      component: () =>
        import("./views/404.vue")
    },



  ],
  scrollBehavior (to, from, savedPosition) {

    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 120 }
      }
    } else {
      return { x: 0, y: 0 }
    }

  }
});
