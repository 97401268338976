import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/main.css";
import VueAnalytics from "vue-analytics";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

/*
Vue.use(VueAnalytics, {
  id: ["UA-147735608-1", "UA-91901108-7"],
  disabled: true,
  router
});

// ME code
Vue.use(VueAnalytics, {
  id: "UA-91901108-7",
  disabled: true,
  router
});
*/

Vue.use(VueCookies);
VueCookies.config("7d");

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
